import {
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  Badge,
  Radio,
  RadioGroup,
  useColorModeValue as mode,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { formatPrice } from './PriceTag';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllItem } from '../../redux/slices/cartSlice';
import axios from 'axios';
import swal from 'sweetalert';
import http from '../../http-common';
import CryptoJS from 'crypto-js';
import { useForm } from 'react-hook-form';

const OrderSummaryItem = props => {
  const { label, value, children } = props;
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  );
};
export const CartOrderSummary = ({
  cart_array,
  trans_type,
  pickup_status,
  shipping_charge,
  disabledisplaytext,
  pickup_statu_msg,
  shipp_orderid,
  shipp_ordermessage,
}) => {
  const [disablebtn, setDisableBtn] = React.useState(true);
  const [razorpaykey, setRazorPayKey] = React.useState(
    'rzp_test_JtGKkR6wQbxWNM'
  );
  const [razorpaysecretkey, setRazorPaySecretKey] = React.useState(
    'quGCueDYOXTU9x97Uh2pCDKg'
  );
  const [selectedPaymentMode, setSelectedPaymentMode] = React.useState('O'); // You can set the default mode here
  const AUTHORIZE_KEY = process.env.REACT_APP_AUTHORIZE_KEY;
  const base_url = useSelector(state => state.baseurl.baseURL); //window.location.origin;  //"https://ecommerce.ondemandcrm.co";  //"https://gadgets.ondemandcrm.co";
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const companysetting = useSelector(state => state.company);
  const company_name =
    companysetting.length > 0
      ? companysetting[0].DATA.company.Companyinfo.company_name
      : 'Horus tech solution';
  const client_coordinates = useSelector(state => state.coordinate);
  const paymentgateway_type =
    companysetting.length > 0 && companysetting[0].DATA.APPGOOG_ID != null
      ? companysetting[0].DATA.paymentgateway_type
      : '';
  const paymentenablecod =
    companysetting.length > 0 && companysetting[0].DATA.enable_cod != null
      ? companysetting[0].DATA.enable_cod
      : '';

  const [isDesktop] = useMediaQuery('(min-width: 1024px)');
  const marginValue = isDesktop ? '50px' : '10px';
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let payFormResponse =
    JSON.parse(localStorage.getItem('payFormResponse')) || null;
  let prefillData = null;

  useEffect(() => {
    if (cart_array.length > 0 && pickup_status > 0) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [cart_array, pickup_status]);
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    let totaltax = 0;
    let grandtotal = 0;
    let chk_type = 0;
    let chk_transaction_type = 0;
    let tot_shipping_charge = 0;
    cart_array.forEach(item => {
      totalQuantity += item.quantity;
      totalPrice += item.price * item.quantity;
      totaltax += item.TotalTax;
      grandtotal += item.Total;
      tot_shipping_charge += item.Shipping;
      chk_type = item.invoice_type > 0 ? 0 : 1;
      chk_transaction_type = item.transaction_type;
    });
    return {
      totalPrice,
      totalQuantity,
      totaltax,
      grandtotal,
      chk_type,
      chk_transaction_type,
      tot_shipping_charge,
    };
  };
  const priceVal = getTotal().totalPrice;
  const priceTotTax = getTotal().totaltax;
  const priceGrandTot = getTotal().grandtotal;
  const checking_type = getTotal().chk_type;
  const checking_transaction_type = getTotal().chk_transaction_type;
  const price_ship_charge = getTotal().tot_shipping_charge;

  const createInvoice = mode => {
    http
      .post(`/ecommerce_api/saveEcommerceInvoice/`, {
        contact_id: localStorage.getItem('contact_id'),
        cart_id: mode,
        shipp_orderid: shipp_orderid,
        shipp_ordermessage: shipp_ordermessage,
      })
      .then(res => {
        if (res.data[0].STATUS == 'SUCCESS') {
          swal('Success ', res.data[0].MSG, 'success');
          dispatch(removeAllItem());
          {
            /* /ecommerce Remove  */
          }
          navigate('/');
          setDisableBtn(false);
        } else {
          setDisableBtn(false);
        }
      })
      .catch(err => {
        swal('Something went wrong', 'error');
        setDisableBtn(false);
      });
  };
  const paymentdone = (id, response, mode) => {
    async function updatetable() {
      let url = `${base_url}/expo_access_api/updateRazorpay/`;
      const res = await axios.post(url, {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        response: response,
        id: id,
      });
      if (res) {
        if (res.data[0].STATUS == 'SUCCESS') {
          //console.log("payment success response");console.log(response);
          if (response.razorpay_payment_id) {
            createInvoice(mode);
          } else {
            setDisableBtn(false);
          }
        }
      }
    }
    updatetable();
  };
  const openCheckout = (
    receipt_id,
    order_id,
    id,
    amount,
    currency,
    name,
    number,
    email,
    mode
  ) => {
    if (order_id) {
      let options = {
        key: razorpaykey,
        amount: amount, // 2000 paise = INR 20, amount in paisa
        name: company_name, //user_details.company_name,
        description: '',
        currency: 'INR',
        image: `${base_url}/viewimage/getcompany`,
        //"order_id": order_id,
        handler: function (response) {
          const generated_signature = CryptoJS.HmacSHA256(
            order_id + '|' + response.razorpay_payment_id,
            razorpaysecretkey
          ).toString();
          //remember uncomment this on live
          //if (generated_signature == response.razorpay_signature) {
          paymentdone(id, response, mode);
          //alert("Payment done successfully");
          //dispatch(ToastNotify(true, "SUCCESS", "Payment done successfully", "success"));
          //}
        },
        prefill: {
          name: name ? name : '',
          email: email ? email : '',
          contact: number ? number : '',
        },
        notes: {
          receiptid: receipt_id,
          paymentFor: mode,
        },
        theme: {
          color: '#1b1a49',
        },
        config: {
          display: {
            blocks: {
              banks: {
                name: 'Methods with Offers',
                instruments: [
                  {
                    method: 'upi',
                  },
                ],
              },
            },
            hide: [
              // {
              // "method": "card"
              // },
              // {
              //   "method": "netbanking"
              // },
              // {
              //   "method": "wallet"
              // }
            ],
            sequence: ['block.banks'],
            preferences: {
              show_default_blocks: true,
            },
          },
        },
      };
      let rzp = new window.Razorpay(options);
      rzp.open();
      rzp.on('payment.failed', function (response) {
        paymentdone(id, response, mode);
      });
    }
  };
  const GenerateOrderId = (
    amount,
    currency,
    contactid,
    contacttype,
    name,
    number,
    email,
    mode
  ) => {
    async function getorderId() {
      let url = `${base_url}/expo_access_api/razorpayOrderId/`;
      const res = await axios.post(url, {
        AUTHORIZEKEY: AUTHORIZE_KEY,
        amount: amount * 100,
        currency: currency,
        contact: contactid,
        type: contacttype,
        mode: mode,
      });
      if (res) {
        if (res.data[0].STATUS == 'SUCCESS') {
          openCheckout(
            res.data[0].DATA.receipt_id,
            res.data[0].DATA.order_id,
            res.data[0].DATA.id,
            amount * 100,
            currency,
            name,
            number,
            email,
            mode
          );
        } else {
          setDisableBtn(false);
        }
      }
    }
    getorderId();
  };

  const handleSubmitEcommRecord = async e => {
    let grand_total = priceVal + shipping_charge;
    prefillData = {
      txnId: 'P' + new Date().getTime(),
      amount: '99.00',
      dateTime: '2021-11-01 12:20:33',
      custMobile: '9876588877',
      custMail: 'devtest@wizgle.com',
      channelId: '0',
      txnType: 'DIRECT',
      returnURL: 'http://localhost:4200/api/v1/transaction/response',
      productId: 'DEFAULT',
      isMultiSettlement: '0',
      instrumentId: 'NA',
      udf1: 'NA',
      udf2: 'NA',
      udf3: 'NA',
      udf4: 'NA',
      udf5: 'NA',
      cardType: 'NA',
      cardDetails: 'NA',
    };
    //console.log(prefillData);
    let url = '/ecommerce_api/onepaytransaction/';
    const res = await http
      .post(url, {
        AUTHORIZEKEY: '',
        transaction: prefillData,
      })
      .then(res => {
        if (res.data[0].STATUS == 'SUCCESS') {
          payFormResponse = res.data[0].payFormResponse;
          localStorage.setItem(
            'payFormResponse',
            JSON.stringify(payFormResponse)
          );
          //console.log("Reload Record");
          window.location.reload();
        }
      });
  };

  //#======== COD Function ==============#//
  const handleCODPayment = cart_id => {
    alert(cart_id);
    http
      .post(`/ecommerce_api/saveCashOnDeliveryInvoice/`, {
        cart_id: cart_id,
      })
      .then(res => {
        if (res.data[0].STATUS == 'SUCCESS') {
          swal('Success ', res.data[0].MSG, 'success');
          dispatch(removeAllItem());
          {
            /* /ecommerce Remove  */
          }
          navigate('/');
          setDisableBtn(false);
        } else {
          swal(res.data[0].MSG);
          setDisableBtn(false);
        }
      })
      .catch(err => {
        swal('Something went wrong', 'error');
        setDisableBtn(false);
      });
  };
  //#======== End COD Function ==============#//

  const handleEcommerceData = e => {
    if (checking_type > 0 && checking_transaction_type == 22) {
      alert('Invoice type is not defined please contact your Administrator');
      return false;
    }
    setDisableBtn(true);
    const { lat, lng } = client_coordinates.coordinate;
    let grand_total = priceVal + shipping_charge;
    http
      .post(`/ecommerce_api/saveEcommerceData/`, {
        contact_id: localStorage.getItem('contact_id'),
        products: JSON.stringify(cart_array),
        shipping_charge: shipping_charge,
        grand_total: grand_total,
        client_latitude: lat,
        client_longitude: lng,
        shipp_orderid: shipp_orderid,
        shipp_ordermessage: shipp_ordermessage,
        paymentmode: selectedPaymentMode,
      })
      .then(res => {
        if (res.data[0].STATUS == 'SUCCESS') {
          if (res.data[0].IS_INVOICE > 0) {
            if (selectedPaymentMode == 'C') {
              handleCODPayment(res.data[0].IS_INVOICE);
            } else {
              if (res.data[0].paymentgatewaytype == 1) {
                GenerateOrderId(
                  priceGrandTot + shipping_charge,
                  'INR',
                  102,
                  1,
                  res.data[0].CONTACT_NAME,
                  res.data[0].CONTACT_MOBILE,
                  res.data[0].CONTACT_EMAIL,
                  'Order-' + res.data[0].IS_INVOICE
                );
                //alert("Invoice"+res.data[0].IS_INVOICE);
                //GenerateOrderId((priceGrandTot+price_ship_charge),"INR",102,1,res.data[0].CONTACT_NAME,res.data[0].CONTACT_MOBILE,res.data[0].CONTACT_EMAIL,"Order-"+res.data[0].IS_INVOICE);
              } else if (res.data[0].paymentgatewaytype == 2) {
                if (res.data[0].URL) {
                  let ccUrl = res.data[0].URL;
                  window.location.href = ccUrl;
                }
              } else if (res.data[0].paymentgatewaytype == 3) {
                payFormResponse = res.data[0].onepayresponse;
                //console.log("Check REspp");console.log(payFormResponse);
                localStorage.setItem(
                  'payFormResponse',
                  JSON.stringify(payFormResponse)
                );
                //console.log("Reload Record");
                window.location.reload();
                return false;
              }
            }
          } else {
            swal('Success ', res.data[0].MSG, 'success');
            dispatch(removeAllItem());
            {
              /* /ecommerce Remove  */
            }
            navigate('/');
            setDisableBtn(false);
          }
        } else {
          swal(res.data[0].MSG);
          setDisableBtn(false);
        }
      })
      .catch(err => {
        swal('Something went wrong', 'error');
        setDisableBtn(false);
      });
  };
  const breakStringAfterTenWords = str => {
    //console.log("breakStringAfterTenWords");console.log(str);
    if (str && str.trim() != '') {
      const wordsArray = str.split(' ');
      const result = [];
      const wordsPerLine = 5;
      while (wordsArray.length > 0) {
        result.push(wordsArray.splice(0, wordsPerLine).join(' '));
      }
      return result.join(' <br/> ');
    }
  };
  if (payFormResponse) {
    prefillData = payFormResponse;
    //console.log("Record Payment");console.log(prefillData.merchantId);console.log(prefillData.postUrl);console.log(prefillData.reqData);
    setTimeout(() => {
      localStorage.removeItem('payFormResponse');
      const pfForm = document.getElementById('payment-form');
      pfForm.submit();
    }, 1000);

    return (
      <div className="App row align-items-center justify-content-center full-height">
        <div className="col-md-4">
          <form
            method="POST"
            className="d-none"
            id="payment-form"
            action={prefillData?.postUrl}
          >
            <div>
              <input
                defaultValue={prefillData?.merchantId}
                type="text"
                {...register('merchantId', { required: true })}
              />
            </div>
            <div>
              <input
                defaultValue={prefillData?.reqData}
                type="text"
                {...register('reqData', { required: true })}
              />
            </div>

            <input type="submit" />
          </form>
          <div className="text-center">
            <h4>Please wait...</h4>
            <div>Do not refresh or press any</div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Stack
        spacing="8"
        marginTop={marginValue}
        borderWidth="1px"
        rounded="lg"
        padding="8"
        width="full"
      >
        <Heading size="md">Summary</Heading>
        <Stack spacing="6">
          <OrderSummaryItem label="Subtotal" value={formatPrice(priceVal)} />
          {trans_type == 22 && (
            <OrderSummaryItem label="Total Tax">
              <Link href="#" textDecor="underline">
                {formatPrice(priceTotTax)}
              </Link>
            </OrderSummaryItem>
          )}
          {/* <OrderSummaryItem label="Shipping Charges">
            <Link href="#" textDecor="underline">
              {price_ship_charge > 0
                ? formatPrice(price_ship_charge)
                : shipping_charge}
            </Link>
          </OrderSummaryItem> */}
          {base_url != 'https://gujaratsports.ondemandcrm.co' && (
            <OrderSummaryItem label="Shipping Charges">
              <Link href="#" textDecor="underline">
                {price_ship_charge > 0
                  ? formatPrice(price_ship_charge)
                  : shipping_charge}
              </Link>
            </OrderSummaryItem>
          )}

          {/* <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            Total
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {price_ship_charge > 0 ? formatPrice(priceGrandTot + price_ship_charge) : formatPrice((priceVal > 0 ? priceVal : 0) + (priceTotTax > 0 ? priceTotTax : 0 ) + (shipping_charge > 0 ? shipping_charge : 0))}
          </Text>
        </Flex> */}
          <Flex justify="space-between">
            <Text fontSize="lg" fontWeight="semibold">
              Total
            </Text>
            <Text fontSize="xl" fontWeight="extrabold">
              {base_url == 'https://gujaratsports.ondemandcrm.co'
                ? formatPrice(
                    (priceVal > 0 ? priceVal : 0) +
                      (priceTotTax > 0 ? priceTotTax : 0)
                  )
                : formatPrice(
                    price_ship_charge > 0
                      ? priceGrandTot + price_ship_charge
                      : (priceVal > 0 ? priceVal : 0) +
                          (priceTotTax > 0 ? priceTotTax : 0) +
                          (shipping_charge > 0 ? shipping_charge : 0)
                  )}
            </Text>
          </Flex>

          {paymentenablecod == 'Y' && (
            <>
              <Text fontSize="lg" fontWeight="semibold">
                Payment Mode :
              </Text>
              <RadioGroup
                value={selectedPaymentMode}
                onChange={setSelectedPaymentMode}
              >
                <Stack spacing={3} direction="row">
                  <Radio value="O">Online</Radio>
                  <Radio value="C">Cash on Delivery</Radio>
                </Stack>
              </RadioGroup>
            </>
          )}
        </Stack>
        <Button
          colorScheme="green"
          size="md"
          borderRadius={0}
          fontSize="md"
          rightIcon={<FaArrowRight />}
          onClick={handleEcommerceData}
          //onClick={handleSubmitEcommRecord}
          disabled={disablebtn}
        >
          Proceed to buy
          {/* {(companysetting.length > 0 && companysetting[0].DATA.label_checkout != null) ? companysetting[0].DATA.label_checkout : "Checkout"} */}
        </Button>
        {disabledisplaytext == true && (
          <>
            {pickup_status > 0 ? (
              ''
            ) : (
              <Badge colorScheme="red">
                <p
                  dangerouslySetInnerHTML={{
                    __html: breakStringAfterTenWords(pickup_statu_msg),
                  }}
                />
              </Badge>
            )}
          </>
        )}
      </Stack>
    );
  }
};
